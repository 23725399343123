import React from "react";

import "./card-restaurante.scss";
import imagemCard from "../assets/images/shattered.png";
import plusIcon from "../assets/icons/plus.svg";

class CardRestaurante extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgCard: imagemCard
        };
    }

    render() {
        return (
            <div className="card-restaurante" key={this.props.restaurante.id} onClick={this.props.onClick}>
                <div className="row">
                    <div className="col desk-1-1 mobile-1-1">
                        <div
                            className={'foto-restaurante ' + 
                                (!this.props.restaurante.url_foto && this.props.restaurante.url_logo ? 'foto-restaurante--logo' : '')}
                            style={{
                                backgroundImage:
                                    "url(" +
                                    (this.props.restaurante.url_foto || this.props.restaurante.url_logo) +
                                    ")"
                            }}
                        ></div>
                    </div>
                </div>
                <div className="row nome-fantasia">
                    <div className="col desk-1-1">
                        {this.props.restaurante.nome_fantasia}
                    </div>
                </div>
                <div className="row row-line">
                    <div className="col desk-1-1">
                        <hr className="line"></hr>
                    </div>
                </div>
                <div className="row">
                    <div className="col desk-1-1">
                        <span className="telefone">
                            Telefone -
                        </span>
                        {" "}{this.props.restaurante.telefone_comercial}
                    </div>
                </div>
                <div className="row">
                    <div className="col desk-1-1 row-labels">
                        <div className="label label-bairro">
                            {this.props.restaurante.bairro_entrega}
                        </div>
                        <div className="label label-categoria">
                            {this.props.restaurante.categoria}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col desk-1-1">
                        <div className="ver-mais">
                            <span className="label-ver-mais">QUERO SABER MAIS</span>
                            <div className="plus-icon">
                                <img src={plusIcon} alt="+" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardRestaurante;
