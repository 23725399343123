import React from "react";

import logoOGlobo from '../assets/img/logotipo-globo.png';
import logoRioMelhorPresente from '../assets/img/logo-rio-melhor-presente.png';

import bgTopo from '../assets/img/bg-topo.png';
import fitaEsquerda from '../assets/img/fita-esquerda.png';
import fitaDireita from '../assets/img/fita-direita.png';
import './header.scss';

function Header() {
    return (
        <div className="Header">
            <header className="head">
                <div className="container">
                    <div className="row">
                        <div className="col mobile-1-1 desk-3-9 hide-mobile hide-tablet">
                            <a
                                href="https://oglobo.globo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="logotipo"
                            >
                                <img src={logoOGlobo} alt="O GLOBO" />
                            </a>
                        </div>
                        <div className="col mobile-1-1 desk-3-9 hide-mobile hide-tablet">
                            <strong className="button button2">
                                Rio, o seu melhor presente
                            </strong>
                        </div>
                        <div className="col mobile-1-1 desk-3-9 text-right">
                            <a
                                data-fancybox="compartilhar"
                                data-src="#compartilhar_popup"
                                href="#compartilhar_popup"
                                className="compartilhar"
                            >
                                <i className="sprite sprite-share"></i> COMPARTILHAR
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <header className="topo" style={{backgroundImage: 'url(' + bgTopo +')'}}>
                <img src={fitaEsquerda} className="fita-esquerda" alt="Detalhe fita esquerda" />
                <img src={fitaDireita} className="fita-direita"  alt="Detalhe fita direita" />
                <div className="container">
                    <div className="row">
                        <div className="col desk-1-1 text-center middle logo-rio-melhor-presente">
                            <img src={logoRioMelhorPresente} alt="Rio, o seu melhor presente" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col desk-2-3 col-to-center text-center vem-ai">
                            <p>
                                O estado do Rio é privilegiado em <br /> turismo e gastronomia.<br />
                                Aproveite essa seleção com preços especiais!
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col desk-5-6 col-to-center text-center">
                            <p className="texto-meio">
                                Uma iniciativa da Secretaria de Estado de Turismo do Rio de Janeiro, 
                                Sesc RJ, Senac RJ e O Globo, a plataforma "Rio, o seu melhor presente" 
                                reúne ótimas opções para você conhecer e curtir o melhor do nosso estado, 
                                sempre seguindo os protocolos de combate à covid-19.<br/>
                                As ofertas estão divididas em três categorias: Onde comer/beber, Onde dormir e O que fazer.<br/>
                                Pesquise, programe-se, cuide-se e aproveite!
                            </p>
                            <a href="https://riooseumelhorpresente.com.br/formulario#form_cadastro" className="ancora button">
                                Quer fazer parte?<br/>Clique e cadastre o seu estabelecimento<br></br> e a promoção.
                            </a>
                        </div>						
                    </div>
                </div>
                <a href="#restaurantes" className="ancora ancora-seta">&nbsp;</a>
            </header>
        </div>
    );
}

export default Header;
