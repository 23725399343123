import React from "react";

import "./card-restaurante.scss";
import "./restaurante-popup.scss";
import successIcon from "../assets/icons/success.svg";
import swal from "sweetalert";

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// eslint-disable-next-line import/first
require("@fancyapps/fancybox");

class ClientePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: false,
      nextOpenCliente: false,
    };
  }

  fetchCliente(idCliente) {
    this.setState({
      cliente: false,
    });

    if (idCliente) {
      fetch(process.env.REACT_APP_API_URL + "clientes/" + idCliente)
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            cliente: response.data,
          });

          this.props.changeMetaTag(
            this.state.cliente.url_foto || this.state.cliente.url_logo,
            this.state.cliente.nome_fantasia +
              " - " +
              this.state.cliente.promocao_vigente
          );

          window.dataLayer.push({
            event: "pageviewVirtualGA",
            urlPathVirtual: window.location.pathname,
          });
          console.log("pageviewVirtualGA: popup");
        })
        .catch(function(error) {
          swal(
            "Erro!",
            "Ocorreu um erro ao tentar obter as informações do cliente",
            "error"
          );
        });
    }
  }

  renderCampo(campo, title, cols = "2", link = false) {
    if (campo) {
      if (link) {
        campo = (
          <a href={campo} target="_blank" rel="noopener noreferrer">
            {campo}
          </a>
        );
      }

      return (
        <div className={"col desk-1-" + cols + " mobile-1-1"}>
          <img className="success-icon" src={successIcon} alt="icone sucesso" />
          <span className="title-label">{title}</span>
          <br />
          <span
            className={
              "texto-label " +
              (title === "Nome Fantasia" ? "nome-fantasia" : "")
            }
          >
            {campo}
          </span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderCategorias(categorias) {
    if (categorias) {
      let rows = [];

      for (let index = 0; index < categorias.length; index++) {
        rows.push(
          <label className="label" key={categorias[index]}>
            {categorias[index]}
          </label>
        );
      }

      return rows;
    } else {
      return null;
    }
  }

  checkIsLink(texto) {
    let url;

    try {
      url = new URL(texto);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  renderPromocao() {
    const cols = 1;
    const title = "Promoção";

    if (this.state.cliente.promocao_vigente) {
      return (
        <div className={"col desk-1-" + cols + " mobile-1-1"}>
          <img className="success-icon" src={successIcon} alt="icone sucesso" />
          <span className="title-label">{title}</span>
          <br />
          <span
            className="texto-label promocao"
            dangerouslySetInnerHTML={{
              __html: this.state.cliente.promocao_vigente,
            }}
          ></span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderCliente() {
    if (this.state.cliente) {
      return (
        <div className="cliente-campos fade-in">
          <div
            className={
              "cliente-logo " +
              (!this.state.cliente.url_foto && this.state.cliente.url_logo
                ? "cliente-logo--logo"
                : "")
            }
            style={{
              backgroundImage:
                "url(" +
                (this.state.cliente.url_foto || this.state.cliente.url_logo) +
                ")",
            }}
          ></div>
          <div className="row">
            <div className="col desk-1-1 nome-fantasia-titulo">
              <h1>{this.state.cliente.nome_fantasia}</h1>
            </div>
          </div>
          <div className="row row-labels">
            <div className="col desk-1-1">
              {this.renderCategorias(this.state.cliente.categorias)}
            </div>
          </div>
          <div className="row">
            <div className="col desk-1-1">
              <hr className="line"></hr>
            </div>
          </div>
          <div className="row row-labels">
            <div className="col desk-1-1">
              <label className="label label-bairro">
                {this.state.cliente.bairro_entrega}
              </label>
              <label className="label label-categoria">
                {this.state.cliente.categoria}
              </label>
            </div>
          </div>
          <div className="row row-label margin">
            {this.renderCampo(
              this.state.cliente.nome_fantasia,
              "Nome Fantasia"
            )}
            {this.renderCampo(
              this.state.cliente.telefone_comercial,
              "Telefone"
            )}
          </div>
          <div className="row row-label">
            {this.renderCampo(this.state.cliente.endereco, "Endereço", 1)}
          </div>
          <div className="row row-label">
            {this.renderCampo(
              this.state.cliente.url_facebook,
              "Facebook",
              2,
              true
            )}
            {this.renderCampo(
              this.state.cliente.url_instagram,
              "Instagram",
              2,
              true
            )}
          </div>
          <div className="row row-label">
            {this.renderCampo(
              this.state.cliente.canal_venda,
              "Canal de venda",
              2,
              this.checkIsLink(this.state.cliente.canal_venda)
            )}
            {this.renderCampo(this.state.cliente.site, "Site", 2, true)}
          </div>
          <div className="row">{this.renderPromocao()}</div>
        </div>
      );
    } else {
      return <div className="loader"></div>;
    }
  }

  render() {
    return (
      <div
        id="cliente_popup"
        className="cliente-popup"
        style={{
          display: "none",
        }}
      >
        <span
          className="fechar"
          onClick={() => {
            // var pathAntigo = window.location.pathname;

            // window.history.back();

            // setTimeout(function() {
            //   var pathNovo = window.location.pathname;

            //   if (pathNovo === pathAntigo) {
            //     window.history.pushState({}, "Clientes", "/clientes/");
            //   }
            // }, 50);
            jQuery.fancybox.close(1);
          }}
        >
          [x] fechar
        </span>
        <div className="wrapper">{this.renderCliente()}</div>
      </div>
    );
  }
}

export default ClientePopup;
