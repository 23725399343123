import React from "react";

import logoSenaiSenac from "../assets/img/logo-senai-senac.png";
import logoOGlobo from "../assets/img/logo-o-globo.png";
import logoGovernoRio from "../assets/img/logo-governo-rio.png";

function Footer() {
  return (
    <div className="Footer">
      <footer className="rodape">
        <div className="container">
          <div className="row">
            <div
              className="col mobile-1-1 desk-4-12 text-center"
              style={{ marginRight: "30px" }}
            >
              <span className="tit">Apoio:</span>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={logoSenaiSenac} alt="Logo Sesc Senai" />
              </a>
            </div>

            <div
              className="col mobile-1-1 desk-4-12 text-center"
              style={{ marginRight: "30px" }}
            >
              <div className="row">
                <span className="tit">Promoção:</span>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    src={logoGovernoRio}
                    alt="Logo Secretaria de Turismo GOVERNO DO ESTADO RIO DE JANEIRO"
                  />
                </a>
              </div>
            </div>
            <div className="col mobile-1-1 desk-3-12 text-center">
              <div className="row">
                <span className="tit">Realização</span>
                <a
                  href="https://oglobo.globo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logoOGlobo}
                    alt="Logo O GLOBO"
                    className="logo-o-globo"
                  />
                </a>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="bg-escuro clearfix">
          <div className="container">
            <div className="row">
              <div className="col mobile-1-1 desk-2-4 redes-sociais">
                Copyright - 2020 GLOBO &nbsp; | &nbsp; Todos os direitos
                reservados
              </div>
              <div className="col mobile-1-1 desk-1-4 redes-sociais">
                <a
                  href="https://www.facebook.com/pages/category/Government-Organization/Setur-Rj-253472398898178/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="flaticon-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/setur_rj/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="flaticon-instagram" />
                </a>
                <a
                  href="https://www.twitter.com/TurismoRJ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="flaticon-twitter" />
                </a>
              </div>
              <div className="col mobile-1-1 desk-1-4 text-right">
                <a
                  data-fancybox="Privacidade"
                  rel="teste1"
                  data-type="iframe"
                  href="https://riooseumelhorpresente.com.br/img/regulamento.html"
                >
                  Privacidade
                </a>
                <a
                  data-fancybox="Termos De Uso"
                  rel="teste1"
                  data-type="iframe"
                  href="https://riooseumelhorpresente.com.br/img/regulamento.html"
                >
                  {" "}
                  Termos de uso
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
