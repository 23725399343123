import React from "react";

class CompartilharPopup extends React.Component {
    shareFacebook() {
        //
    }

    render() {
        return (
            <div
                id="compartilhar_popup"
                style={{
                    display: "none"
                }}
            >
                <ul>
                    <li>
                        <a
                            id="fb-share"
                            onClick={() => window.open('https://www.facebook.com/sharer.php?u='+window.location.href )}
                            href="#facebook"
                            className="face"
                            alt="Facebook"
                            title="Facebook"
                        >
                            <i className="sprite sprite-facebook" />
                            <strong>FACEBOOK</strong>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://twitter.com/intent/tweet?url=https://riooseumelhorpresente.com.br/clientes"
                            className="twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Twitter"
                        >
                            <i className="sprite sprite-twitter" />
                            <strong>TWITTER</strong>
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => window.open('https://www.linkedin.com/shareArticle?url='+window.location.href+'&title=Festival Delivery Rio&summary=Bernardo Gardin&source=https://riooseumelhorpresente.com.br/clientes','sharer','toolbar=0,status=0,width=548,height=325' )}
                            href="#linkedin"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="share-btn linkedin"
                        >
                            <i className="sprite sprite-linkedin" />
                            <strong>LINKEDIN</strong>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default CompartilharPopup;
