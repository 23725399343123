import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// eslint-disable-next-line
import "./assets/js/front";
import "./assets/css/front.css";
import "./App.scss";

import Clientes from "./pages/Clientes";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import CompartilharPopup from "./components/CompartilharPopup";
import ClientePopup from "./components/ClientePopup";

import MetaTags from 'react-meta-tags';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nextOpenCliente: false,
            metaTag: 'https://festivaldeliveryrio.com.br/clientes/build/images/fb-img.jpg',
            description: 'Você está prestes a se tornar um parceiro do projeto “Rio, o seu melhor presente”, desenvolvido pela Secretaria de Estado de Turismo do Rio de Janeiro, o Sesc RJ, o Senac RJ e O Globo. Decidimos unir os melhores serviços turísticos e criar o maior festival de turismo do Rio, que consiste na criação de uma campanha promocional e um site onde o trade turístico terá a oportunidade de conceder descontos especiais para os turistas, a fim de promover e estimular o turismo em nosso estado. Dessa forma, os turistas poderão acessar o portal www.riooseumelhorpresente.com.br e comprar diretamente com o profissional de turismo, programando sua próxima viagem ou um ótimo presente para sua família e amigos.'
        }

        this.clientePopupComponent = new React.createRef();
    }

    visualizarCliente(id) {
        if (this.clientePopupComponent.current !== null) {
            this.clientePopupComponent.current.fetchCliente(id);
        } else {
            this.setState({
                nextOpenCliente: id
            }, () => {
                this.clientePopupComponent.current.fetchCliente(this.state.nextOpenCliente);
            });
        }
    }

    changeMetaTag(imagePath, description) {
        this.setState({
            metaTag: imagePath,
            description: description
        });
    }

    render() {
        return (
            <div className="App">
                <MetaTags>
                    <meta property="og:image" content={this.state.metaTag} />
                    <meta property="og:description" content={this.state.description} />
                </MetaTags>
                <Header></Header>
                <Router basename="/clientes">
                    <Switch>
                        <Route path={`/:clienteId`} render={routeProps => {
                            return <Clientes visualizarCliente={(id) => this.visualizarCliente(id)}
                                {...routeProps} />
                            }} />
                        <Route path={`/`}>
                            <Clientes visualizarCliente={(id) => this.visualizarCliente(id)}/>
                        </Route>
                    </Switch>
                </Router>
                <Footer></Footer>
                <CompartilharPopup />
                <ClientePopup ref={this.clientePopupComponent} changeMetaTag={(metaTag, description) => this.changeMetaTag(metaTag, description)} />
            </div>
        );
    }
}

export default App;
