import React from "react";
import CardRestaurante from "../components/CardRestaurante";
import "./restaurantes.scss";

import Select from "react-select";
import swal from "sweetalert";

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// eslint-disable-next-line import/first
require("@fancyapps/fancybox");

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#f5f5f5",
    padding: "3px 0px;",
    marginTop: "6px",
    border: "1px solid #d9d9d9",
    borderRadius: "5px;",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#1086a9",
      borderRadius: "15px",
      padding: "4px 10px",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#1086a9",
      color: "white",
    },
  }),
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientes: {},
      regioesEntregas: {},
      categorias: {},
      currentPagina: 1,
      ultimaPagina: 1,
      filtros: {},
      abortController: null,
      signalFiltrar: null,
    };

    this.nomeInput = React.createRef();
    this.bairroInput = React.createRef();
    this.categoriaInput = React.createRef();
  }

  fetchClientes(
    pagina = 1,
    filtros = { nome: "", bairros: [], categorias: [] }
  ) {
    this.setState({
      loading: true,
    });

    if (filtros !== this.state.filtros) {
      pagina = 1;
    }

    this.setState({
      filtros: filtros,
      currentPagina: pagina,
    });

    fetch(
      process.env.REACT_APP_API_URL +
        "clientes?page=" +
        pagina +
        "&nome=" +
        (filtros.nome || "") +
        "&bairros=" +
        (filtros.bairros || "") +
        "&categorias=" +
        (filtros.categorias || ""),
      {
        method: "get",
        signal: this.state.signalFiltrar,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          currentPagina: response.meta.current_page,
          ultimaPagina: response.meta.last_page,
          clientes: [...response.data],
          filtros: filtros,
          loading: false,
        });
      })
      .catch(function(error) {
        if (error.code === 20) {
          return;
        }

        swal("Erro!", "Ocorreu um erro ao tentar buscar os clientes", "error");
      });
  }

  fetchMaisClientes(pagina = 1, filtros = {}) {
    if (
      (filtros.nome || "") !== (this.state.filtros.nome || "") ||
      JSON.stringify(filtros.bairros || []) !==
        JSON.stringify(this.state.filtros.bairros || []) ||
      JSON.stringify(filtros.categorias || []) !==
        JSON.stringify(this.state.filtros.categorias || [])
    ) {
      pagina = 1;
    }

    this.setState({
      filtros: filtros,
      currentPagina: pagina,
    });

    fetch(
      process.env.REACT_APP_API_URL +
        "clientes?page=" +
        pagina +
        "&nome=" +
        (filtros.nome || "") +
        "&bairros=" +
        (filtros.bairros || "") +
        "&categorias=" +
        (filtros.categorias || "")
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          currentPagina: response.meta.current_page,
          ultimaPagina: response.meta.last_page,
          clientes: [...this.state.clientes.concat(response.data)],
        });
      })
      .catch(function(error) {
        swal("Erro!", "Ocorreu um erro ao tentar buscar os clientes", "error");
      });
  }

  handleClickCarregarMaisButton(e) {
    e.preventDefault();

    if (this.state.ultimaPagina === this.state.currentPagina) {
      return;
    }

    let filtros = this.getFiltros();

    this.fetchMaisClientes(this.state.currentPagina + 1, filtros);
  }

  fetchCategorias() {
    fetch(process.env.REACT_APP_API_URL + "categorias")
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          categorias: [...response.data],
        });
      })
      .catch(function(error) {
        swal(
          "Erro!",
          "Ocorreu um erro ao tentar buscar as categorias",
          "error"
        );
      });
  }

  fetchRegioesEntregas() {
    fetch(process.env.REACT_APP_API_URL + "regioes-entregas")
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          regioesEntregas: [...response.data],
        });
      })
      .catch(function(error) {
        swal("Erro!", "Ocorreu um erro ao tentar buscar as cidades", "error");
      });
  }

  handleFiltrar(appendFiltro = false) {
    let filtros = this.getFiltros();

    if (appendFiltro) {
      if (typeof filtros[appendFiltro.propriedade] === "undefined") {
        filtros[appendFiltro.propriedade] = [];
      }

      if (appendFiltro.values) {
        filtros[appendFiltro.propriedade] = appendFiltro.values.map(function(
          item
        ) {
          return item.value;
        });
      } else {
        filtros[appendFiltro.propriedade] = null;
      }
    }

    try {
      this.state.abortController.abort();
    } catch (e) {
      // console.log(e);
    }

    const abortController = new AbortController();

    this.setState(
      {
        abortController: abortController,
        signalFiltrar: abortController.signal,
      },
      () => {
        var fullPath =
          "/" +
          "clientes?page=" +
          this.state.currentPagina +
          "&nome=" +
          (filtros.nome || "") +
          "&bairros=" +
          (filtros.bairros || "") +
          "&categorias=" +
          (filtros.categorias || "");

        window.history.pushState(fullPath, "Clientes buscar", fullPath);

        window.dataLayer.push({
          event: "pageviewVirtualGA",
          urlPathVirtual: fullPath,
        });
        console.log('pageviewVirtualGA: filtrar');

        this.fetchClientes(this.state.currentPagina, filtros);
      }
    );
  }

  handleClickCardRestaurante(id) {
    window.history.pushState(id, "Cliente", "/clientes/" + id);
    this.props.visualizarCliente(id);

    jQuery.fancybox.open({
      src: "#cliente_popup",
      type: "inline",
      afterClose: function () {
        var pathAntigo = window.location.pathname;
        
        window.history.back();

        setTimeout(function() {
          var pathNovo = window.location.pathname;

          if (pathNovo === pathAntigo) {
            window.history.pushState({}, "Clientes", "/clientes/");
          }
        }, 50);
      }
    });
  }

  getFiltros() {
    let bairros = this.bairroInput.current.state.value
      ? [...this.bairroInput.current.state.value.map((item) => item.value)]
      : [];
    let categorias = this.categoriaInput.current.state.value
      ? [...this.categoriaInput.current.state.value.map((item) => item.value)]
      : [];

    return {
      nome: this.nomeInput.current.value,
      bairros: bairros,
      categorias: categorias,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const abortController = new AbortController();
    const signalFiltrar = abortController.signal;
    this.setState(
      {
        abortController: abortController,
        signalFiltrar,
      },
      function() {
        this.fetchClientes();
        this.fetchCategorias();
        this.fetchRegioesEntregas();

        this.checkRoute();
      }
    );
  }

  checkRoute() {
    if (
      typeof this.props.match !== "undefined" &&
      typeof this.props.match.params.clienteId !== "undefined" &&
      Number(this.props.match.params.clienteId) > 0
    ) {
      this.handleClickCardRestaurante(
        Number(this.props.match.params.clienteId)
      );
    }
  }

  renderCard(restaurante) {
    if (restaurante === false) {
      return (
        <div className="col text-center desk-1-1 margin" key="1">
          <p>Nenhum cliente foi encontrado.</p>
        </div>
      );
    }

    return (
      <div className="col desk-1-4 mobile-1-1" key={restaurante.id}>
        <CardRestaurante
          restaurante={restaurante}
          onClick={() => this.handleClickCardRestaurante(restaurante.id)}
        ></CardRestaurante>
      </div>
    );
  }

  handleSelectCategoriasChange(inputValue, { action }) {
    if (action === "select-option" || action === "remove-value") {
      var appendFiltro = {
        propriedade: "categorias",
        values: inputValue,
      };

      this.handleFiltrar(appendFiltro);
    }
  }

  handleSelectBairrosChange(inputValue, { action }) {
    if (action === "select-option" || action === "remove-value") {
      var appendFiltro = {
        propriedade: "bairros",
        values: inputValue,
      };

      this.handleFiltrar(appendFiltro);
    }
  }

  render() {
    let rows = [];

    if (typeof this.state.clientes !== "undefined") {
      for (let i = 0; i < this.state.clientes.length; i++) {
        rows.push(this.state.clientes[i]);
      }
    }

    if (
      typeof this.state.clientes === "undefined" ||
      this.state.clientes.length <= 0
    ) {
      rows.push(false);
    }

    let loading = false;

    if (this.state.loading) {
      loading = <div className="loader"></div>;
    }

    let carregarMais = null;

    if (this.state.ultimaPagina !== this.state.currentPagina) {
      carregarMais = (
        <div className="row row-carregar-mais">
          <div className="col col-to-center desk-1-1">
            <button
              id="button-carregar-mais"
              className="button"
              onClick={this.handleClickCarregarMaisButton.bind(this)}
            >
              CARREGAR MAIS
            </button>
          </div>
        </div>
      );
    }

    return (
      <div id="clientes" className="Home">
        <section className="form">
          <form action="#">
            <div className="container">
              <div className="row">
                <div className="col desk-1-1 col-banner  col-to-center">
                  <div id="pub-super-1"></div>
                </div>
              </div>
              <div className="row">
                <div className="title-form title-form-filtrar">buscar</div>
              </div>
              <div className="row">
                <div className="col desk-1-1">
                  <div className="box box-filter">
                    <div className="row">
                      <div className="col desk-1-3 mobile-1-1">
                        <label htmlFor="nome">Nome do estabelecimento</label>
                        <input
                          name="nome"
                          type="text"
                          placeholder="Digite o nome"
                          ref={this.nomeInput}
                          onChange={this.handleFiltrar.bind(this)}
                          autoFocus
                        />
                      </div>
                      <div className="col desk-1-3 mobile-1-1">
                        <label htmlFor="nome">
                          Selecione uma ou mais cidades
                        </label>
                        <Select
                          isMulti
                          placeholder="Selecione a(s) cidade(s)"
                          onChange={this.handleSelectBairrosChange.bind(this)}
                          options={this.state.regioesEntregas}
                          ref={this.bairroInput}
                          formatGroupLabel={formatGroupLabel}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#d9d9d9",
                              primary50: "#d9d9d9",
                              primary: "#d9d9d9",
                            },
                          })}
                        />
                      </div>
                      <div className="col desk-1-3 mobile-1-1">
                        <label htmlFor="nome">Categoria</label>
                        <Select
                          isMulti
                          placeholder="Selecione a(s) categoria(s)"
                          onChange={this.handleSelectCategoriasChange.bind(
                            this
                          )}
                          options={this.state.categorias}
                          ref={this.categoriaInput}
                          formatGroupLabel={formatGroupLabel}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#d9d9d9",
                              primary50: "#d9d9d9",
                              primary: "#d9d9d9",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"row row-clientes " + (loading || "fade-in")}>
                {loading || rows.map(this.renderCard.bind(this))}
              </div>

              <div className={"row row-clientes " + (loading || "fade-in")}>
                <div className="col col-to-center">
                  <div id="pub-retangulo-1"></div>
                </div>
              </div>
              {carregarMais}
            </div>
          </form>
        </section>
      </div>
    );
  }
}

export default Clientes;
